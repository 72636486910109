import React from 'react';

import logo from '../assets/img/logo.svg'

import './Title.scss'

const Title = () => {
    return (
        <div className="Title">
            <img src={logo} alt="Buildings Living"/>
        </div>
    );
};

export default Title;