import React from 'react';
 
import './Footer.scss'

const Footer = () => {
    const date = new Date().getFullYear()
    return (
        <div className="Footer">
            <div className="Footer_credits">&copy; {date} BUILDINGS LIVING B.V. All rights reserved</div>
            <div className="Footer_nav">
                <a href="./algemene-voorwaarden.pdf" target="_blank">Terms &amp; Policies</a>
                <a href="./privacy-statement.pdf" target="_blank">Privacy Declaration</a>
            </div>
        </div>
    );
};

export default Footer;